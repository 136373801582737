import { ReactComponent as CheckMark } from "@assets/svg/Checkmark.svg";
import {
  Box,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import { CheckoutStep } from "@components/Checkout/CheckoutStep.component";
import { FormField } from "@components/FormField.component";
import { useAppData } from "@hooks/useAppData";
import { Field } from "formik";
import parsePhonenumberWithPreference from "libs/chs-core/util/PhoneNumberHelper";
import { useLocale, useTranslations } from "next-intl";
import { Dispatch, SetStateAction, useState } from "react";

interface CheckoutAddressFormProps {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  sameInvoice: boolean;
  setSameInvoice: Dispatch<SetStateAction<boolean>>;
}

function CheckoutAddressForm({
    setFieldValue,
    sameInvoice,
    setSameInvoice 
  }: CheckoutAddressFormProps) {
  const dict = useTranslations("CheckoutAddressPage.AddressForm");
  const validationDict = useTranslations("Shared.Validation");
  const locale = useLocale();
  const isJp = locale === "ja-jp";

  const [
    {
      marketData: { CountryName, CountryTwoLetterIsoCode },
    },
  ] = useAppData();

  function validateEmail(value: string) {
    let error;
    if (
      !value ||
      value === "" ||
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ) {
      error = validationDict("ErrorInvalidEmail");
    }
    return error;
  }

  function validateTel(value: string) {
    const countryCode = CountryTwoLetterIsoCode.toUpperCase();
    const parsedPhoneNumber = parsePhonenumberWithPreference(
      value,
      countryCode
    );
    if (!parsedPhoneNumber.isValid()) {
      return validationDict("ErrorInvalidPhone");
    }
    return undefined;
  }

  function validateEmpty(value: string) {
    let error;
    if (!value || value === "") {
      error = validationDict("ErrorEmptyField");
    }
    return error;
  }
  return (
    <>
      <CheckoutStep {...{ Step: "1", Heading: dict("HeadingAddress") }} />

      <Box px={{ base: 2, md: 14 }} py={4}>
        <Stack spacing={3}>
          <FormField
            name="AddressInputModel.DeliveryAddress.Email"
            type="email"
            label={dict("InputLabelEmail")}
            placeholder={dict("InputPlaceholderEmail")}
            validate={validateEmail}
            autoComplete="email"
            validateCheckMark
          />

          <FormField
            name="AddressInputModel.DeliveryAddress.Name"
            label={dict("InputLabelName")}
            placeholder={dict("InputPlaceholderName")}
            validate={validateEmpty}
            autoComplete="name"
            validateCheckMark
          />

          {isJp ? (
            <FormField
              name="AddressInputModel.DeliveryAddress.Address1Furigana"
              label={dict("AddressFurigana")}
              placeholder={dict("AddressFurigana")}
              validate={validateEmpty}
              validateCheckMark
            />
          ) : (
            <></>
          )}

          <FormField
            name="AddressInputModel.DeliveryAddress.Address1"
            label={dict("InputLabelAddress")}
            placeholder={dict("InputPlaceholderAddress")}
            validate={validateEmpty}
            autoComplete="address-line1"
            validateCheckMark
          />

          <Flex gap={3}>
            <Box w={175}>
              <FormField
                name="AddressInputModel.DeliveryAddress.ZipCode"
                label={dict("InputLabelZip")}
                placeholder={dict("InputPlaceholderZip")}
                validate={validateEmpty}
                autoComplete="postal-code"
                validateCheckMark
                marginTop="-0.5"
              />
            </Box>
            <Box flex={1}>
              <FormField
                name="AddressInputModel.DeliveryAddress.City"
                label={dict("InputLabelCity")}
                placeholder={dict("InputPlaceholderCity")}
                validate={validateEmpty}
                autoComplete="address-level2"
                validateCheckMark
                marginTop="-0.5"
              />
            </Box>
          </Flex>

          <FormField
            name="AddressInputModel.DeliveryAddress.Country"
            type="text"
            // label={dict("InputLabelCountry")}
            placeholder={dict("InputPlaceholderCountry")}
            disabled={true}
            value={CountryName}
          />

          <Field
            name="AddressInputModel.DeliveryAddress.PhoneNumber"
            validate={validateTel}
          >
            {({ field, form, meta }) => (
              <FormControl
                isInvalid={meta.error && meta.touched}
                isRequired={!!validateTel}
                display={"unset"}
                variant="floating"
              >
                <Field
                  as={Input}
                  {...field}
                  type="tel"
                  placeholder=" "
                  focusBorderColor="black"
                  autoComplete="tel"
                  borderRadius={0}
                  onChange={(e) => {
                    const oldPhonenumber =
                      form.values.AddressInputModel?.DeliveryAddress
                        .PhoneNumber;
                    const newPhoneNumber = e.target.value;
                    const countryCode = CountryTwoLetterIsoCode.toUpperCase();
                    const parsedPhoneNumber = parsePhonenumberWithPreference(
                      e.target.value,
                      countryCode
                    );
                    if (parsedPhoneNumber.isValid()) {
                      setFieldValue(
                        "AddressInputModel.DeliveryAddress.PhoneNumber",
                        parsedPhoneNumber.formatInternational()
                      );
                    } else {
                      setFieldValue(
                        "AddressInputModel.DeliveryAddress.PhoneNumber",
                        newPhoneNumber
                      );
                    }
                  }}
                />
                <FormLabel my={2} ml={0}>
                  {dict("InputLabelPhone")}
                </FormLabel>
                {/* condition test for field has been touched without error */}
                {!meta.error && meta.touched ? (
                  <Icon
                    as={CheckMark}
                    position="absolute"
                    right="4"
                    top="50%"
                    transform="translateY(-50%)"
                  />
                ) : (
                  <></>
                )}
                <FormErrorMessage>{meta.error}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Text size="sx">{dict("TextPhoneFieldDescription")}</Text>

          <Checkbox
            defaultChecked
            onChange={() => setSameInvoice(!sameInvoice)}
          >
            {dict("CheckboxLabelBillingAddressSameAsDelivery")}
          </Checkbox>

          <Divider borderColor="blackAlpha.300" />

          {!sameInvoice && (
            <>
              <Heading as="h3" size="lg" ml={4} py={4}>
                {dict("HeadingBillingAddress")}
              </Heading>
              <Stack spacing={3}>
                <FormField
                  name="AddressInputModel.SeperateInvoiceAddress.Email"
                  type="email"
                  label={dict("InputLabelEmail")}
                  placeholder={dict("InputPlaceholderEmail")}
                  validate={validateEmail}
                  autoComplete="email"
                  validateCheckMark
                />

                <FormField
                  name="AddressInputModel.SeperateInvoiceAddress.Name"
                  label={dict("InputLabelName")}
                  placeholder={dict("InputPlaceholderName")}
                  validate={validateEmpty}
                  autoComplete="name"
                  validateCheckMark
                />

                {isJp ? (
                  <FormField
                    name="AddressInputModel.SeperateInvoiceAddress.Address1Furigana"
                    label={dict("AddressFurigana")}
                    placeholder={dict("AddressFurigana")}
                    validate={validateEmpty}
                    validateCheckMark
                  />
                ) : (
                  <></>
                )}

                <FormField
                  name="AddressInputModel.SeperateInvoiceAddress.Address1"
                  label={dict("InputLabelAddress")}
                  placeholder={dict("InputPlaceholderAddress")}
                  validate={validateEmpty}
                  autoComplete="address-line1"
                  validateCheckMark
                />
                <FormField
                  name="VatNum"
                  label={dict("InputLabelVat")}
                  placeholder={dict("InputPlaceholderVat")}
                />

                <Flex gap={3}>
                  <Box w={175}>
                    <FormField
                      name="AddressInputModel.SeperateInvoiceAddress.ZipCode"
                      label={dict("InputLabelZip")}
                      placeholder={dict("InputPlaceholderZip")}
                      validate={validateEmpty}
                      autoComplete="postal-code"
                      validateCheckMark
                      marginTop="-0.5"
                    />
                  </Box>
                  <Box flex={1}>
                    <FormField
                      name="AddressInputModel.SeperateInvoiceAddress.City"
                      label={dict("InputLabelCity")}
                      placeholder={dict("InputPlaceholderCity")}
                      validate={validateEmpty}
                      autoComplete="address-level2"
                      validateCheckMark
                      marginTop="-0.5"
                    />
                  </Box>
                </Flex>

                <Field
                  name="AddressInputModel.SeperateInvoiceAddress.PhoneNumber"
                  validate={validateTel}
                >
                  {({ field, form, meta }) => (
                    <FormControl
                      isInvalid={meta.error && meta.touched}
                      isRequired={!!validateTel}
                      display={"unset"}
                      variant="floating"
                    >
                      <Field
                        as={Input}
                        {...field}
                        type="tel"
                        placeholder=" "
                        focusBorderColor="black"
                        autoComplete="tel"
                        borderRadius={0}
                        onChange={(e) => {
                          const oldPhonenumber =
                            form.values.SeperateInvoiceAddress?.PhoneNumber;
                          const newPhoneNumber = e.target.value;
                          const countryCode =
                            CountryTwoLetterIsoCode.toUpperCase();
                          const parsedPhoneNumber =
                            parsePhonenumberWithPreference(
                              e.target.value,
                              countryCode
                            );
                          if (parsedPhoneNumber.isValid()) {
                            setFieldValue(
                              "AddressInputModel.SeperateInvoiceAddress.PhoneNumber",
                              parsedPhoneNumber.formatInternational()
                            );
                          } else {
                            setFieldValue(
                              "AddressInputModel.SeperateInvoiceAddress.PhoneNumber",
                              newPhoneNumber
                            );
                          }
                        }}
                      />
                      <FormLabel my={2} ml={0}>
                        {dict("InputLabelPhone")}
                      </FormLabel>
                      {/* condition test for field has been touched without error */}
                      {!meta.error && meta.touched ? (
                        <Icon
                          as={CheckMark}
                          position="absolute"
                          right="4"
                          top="50%"
                          transform="translateY(-50%)"
                        />
                      ) : (
                        <></>
                      )}
                      <FormErrorMessage>{meta.error}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Text size="sx">{dict("TextPhoneFieldDescription")}</Text>
              </Stack>

              <Divider borderColor="blackAlpha.300" />
            </>
          )}
        </Stack>
      </Box>
    </>
  );
}

export default CheckoutAddressForm;
